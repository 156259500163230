<template lang="pug">
TableV2.mx-4(
  :isViewAdd="false"
  :headers="headers"
  :items="items.results"
  :pageCount="items.count"
  :currentPage="items.current"
  :querySearch="getData"
  :isLoading="isLoading"
  showExpand
  componentInfo="BackOfficeMedicalTable"
  :actions="actions"
)
  Add(slot="AddBlock" @save="setPriceForMedical")
</template>
<script>
import { mapState, mapActions } from 'vuex'
export default {
  components: {
    Add: () => import('./Form.vue')
  },
  data () {
    return {
      headers: [
        { value: 'value', text: this.$t('nameMedicalInstitution') },
        { value: 'event', text: this.$t('actions'), class: 'mw-0' },
        { text: '', value: 'data-table-expand' }
      ],
      isLoading: true,
      actions: [
        {
          id: 1,
          tooltip: 'tooltip.deactive',
          action: (item) => this.changedActiveStatus(item.id, true),
          color: 'success',
          name: 'mdi-lock-open-variant-outline',
          checkAccess: (item) => !item.is_disabled
        },
        {
          id: 2,
          tooltip: 'tooltip.active',
          action: (item) => this.changedActiveStatus(item.id, false),
          color: 'error',
          name: 'mdi-lock-open-outline',
          checkAccess: (item) => item.is_disabled
        }
      ]
    }
  },
  computed: {
    ...mapState({
      items: state => state.backoffice.medical
    })
  },
  methods: {
    ...mapActions(['getPriceForMedicalInstitution', 'setPriceForMedicalInstitution', 'updateStateMedicalInstitution']),
    async getData (filter) {
      this.isLoading = true
      filter.have_medical_position = true
      await this.getPriceForMedicalInstitution(filter)
      this.isLoading = false
    },
    async setPriceForMedical (body) {
      await this.setPriceForMedicalInstitution({ body })
    },
    changedActiveStatus (id, isDisabled) {
      this.$swal({
        icon: 'warning',
        title: this.$t('Do you sure that you want med institution', { state: isDisabled ? this.$t('deactive') : this.$t('active') }),
        buttons: [this.$t('no'), this.$t('yes')]
      }).then(async confirm => {
        if (confirm) {
          await this.updateStateMedicalInstitution({ id, body: { is_disabled: isDisabled }, params: { ...this.$route.query, have_medical_position: true } })
        }
      })
    }
  }
}
</script>
